<template>
  <div class="content">
    <sub-header v-if="theme" />
    <component
      :is="pageTemplate"
      v-if="pageTemplate && $isNotNullOrUndefined(content)"
      :content="content"
    />
  </div>
</template>

<script>
import { vwoCode } from '@/utils/vwo.js';

// Import all page templates
const components = {
  frontPage: () => import('@/components/templates/FrontPage'),
  contentPage: () => import('@/components/templates/ContentPage'),
  event: () => import('@/components/templates/ContentPage'),
  project: () => import('@/components/templates/ContentPage'),
  blog: () => import('@/components/templates/ContentPage'),
  receiptPage: () => import('@/components/templates/ContentPage'),
  articlesPage: () => import('@/components/templates/ContentPage'),
  basketPage: () => import('@/components/templates/BasketPage'),
  searchPage: () => import('@/components/templates/SearchPage'),
  customerCenterPage: () => import('@/components/templates/CustomerCenterPage'),
  requestNewPasswordPage: () => import('@/components/templates/requestNewPasswordPage'),
  editUserPage: () => import('@/components/templates/EditUserPage'),
  SubHeader: () => import('@/components/organisms/SubHeader'),
  productPage: () => import('@/components/templates/ContentPage'),
  categories: () => import('@/components/templates/ContentPage'),
  checkoutPage: () => import('@/components/templates/ContentPage'),
  impersonationPage: () => import('@/components/templates/ContentPage'),
  createUserPage: () => import('@/components/templates/ContentPage'),
  favoritePage: () => import('@/components/templates/ContentPage'),
  assortmentsPage: () => import('@/components/templates/ContentPage'),
  latestBoughtProductsPage: () => import('@/components/templates/ContentPage'),
  myAccountPage: () => import('@/components/templates/ContentPage'),
  resetPasswordPage: () => import('@/components/templates/ContentPage'),
  recipe: () => import('@/components/templates/ContentPage'),
};

export default {
  components,
  watchQuery: ['q'],
  // Key for <NuxtChild> (transitions)
  key: to => to.fullPath,
  // Called to know which transition to apply
  transition (to, from) {
    if (!from) {
      return 'slide-up';
    }
    return + to.query.page < + from.query.page ? 'slide-down' : 'slide-up';
  },
  async asyncData ({ payload }) {
    return payload;
  },
  computed: {
    pageTemplate () {
      if (this.meta?.template) {
        return this.meta.template;
      } else {
        console.warn(`The template for '${this.meta?.template}' was not found.`);
        return 'contentPage'; // This is just a default for now
      }
    },
    orgSchema () {
      return this.$store.getters['site/getSiteSettings']?.organizationSchema;
    },
    pageUid () {
      return this.$store.getters['content/content/getPageUid'];
    },
    theme () {
      return this.$store.getters['content/content/getTheme'];
    },
  },
  created () {
    const authQueryToken = this.$route.query?.token;
    if (process.client && authQueryToken) {
      window.history.replaceState(null, null, this.$route.path);
    }
  },
  layout: ({ route, payload, layout }) => {
     if (payload?.meta?.template == 'customerCenterPage' && route.query?.orderId) {
       return 'Blank'
     }
     else {return layout}
  },
  methods: {
    getCenter (focalPoint)
    {
      return Object.values(focalPoint).reverse().join(',');
    },
    getImageUrl (image) {
      return `${this.cdnUrl}${image[0].Url}?mode=crop&width=1200&height=630`
    },
    isEcomPageType (type) {
      return (type === 'ContentProductList' || type === 'ContentProduct' || type === 'ContentCategory')
    },
    metaTitle (type) {
      if(this.isEcomPageType(type)) {
        return this.meta?.title
      } else {
        return this.seo?.metaTitle
      }
    },
    metaDesc (type) {
      if(this.isEcomPageType(type)) {
        return this.meta?.metaDescription
      } else {
        return this.seo?.metaDescription
      }
    }
  },
  head () {
    // Set all SEO stuff inside the head
    if (!this.seo) {
      return {};
    } else {
      if (this.seo.hrefLang) {
        this.seo.hrefLang.forEach(hrefLang => {
          let hreflangObject = {};
          hreflangObject.rel = 'alternate';
          hreflangObject.href = hrefLang.url;
          hreflangObject.hreflang = hrefLang.culture;
        });
      }
      const robots =
        this.host.indexOf('nozebrahosting') !== -1
          ? 'noindex, nofollow'
          : `${this.seo.noindex ? 'noindex' : 'index'} ${
              this.seo.nofollow ? 'nofollow' : 'follow'
            }`;
      return {
        title: this.metaTitle(this.meta.type),
        htmlAttrs: {
          lang: this.meta?.culture ? this.meta?.culture.split('-')[0] : 'en'
        },
        bodyAttrs: {
          class: 'o-page' + (this.theme ? ` -${this.theme}` : '')
        },
        script: [
          {
            hid: 'orgSchema',
            type: 'application/ld+json',
            json: this.orgSchema && JSON.parse(this.orgSchema) ? JSON.parse(this.orgSchema) : ''
          },
          {
            type: 'text/javascript',
            id: 'vwoCode',
            innerHTML: vwoCode
          }
        ],
        link: [
          {
            hid: 'canonical',
            rel: 'canonical',
            href: 'https://' + this.meta.hostName + (this.seo?.canonicalUrl ? this.seo.canonicalUrl : this.$route.path )
          }
        ],
        meta: [
          // general
          {
            hid: 'description',
            name: 'description',
            content: this.metaDesc(this.meta.type),
          },
          {
            hid: 'robots',
            name: 'robots',
            content: robots
          },
          // Facebook
          {
            hid: 'facebook-domain-verification',
            name: 'facebook-domain-verification',
            content: 'rodn9stpis6sbiyt4s2a3nc0aw5a0u'
          },
          {
            hid: 'og:title',
            property: 'og:title',
            content: this.social?.ogTitle ? this.social?.ogTitle : this.seo?.metaTitle
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: this.social?.ogDescription ? this.social?.ogDescription : this.metaDesc(this.meta.type)
          },
          {
            hid: 'og:type',
            property: 'og:type',
            content: this.social?.ogType ? this.social?.ogType : 'website'
          },
          // { hid: "og:url", property: "og:url", content: this.seo.facebook.url },
          {
            hid: 'og:image',
            property: 'og:image',
            content: this.social?.ogImage && this.social?.ogImage[0]?.Url ? this.getImageUrl(this.social?.ogImage) : ''
          },
          // Twitter
          {
            hid: 'twitter:card',
            property: 'twitter:card',
            content: this.social?.twitterCard ? this.social?.twitterCard : 'summary'
          },
          {
            hid: 'twitter:title',
            property: 'twitter:title',
            content: this.social?.ogTitle ? this.social?.ogTitle : this.seo?.metaTitle
          },
          {
            hid: 'twitter:description',
            property: 'twitter:description',
            content: this.social?.ogDescription ? this.social?.ogDescription : this.metaDesc(this.meta.type)
          },
          {
            hid: 'twitter:image',
            property: 'twitter:image',
            content: this.social?.ogImage && this.social?.ogImage[0]?.Url ? this.getImageUrl(this.social?.ogImage) : ''
          },
          {
            hid: 'twitter:site',
            property: 'twitter:site',
            content: this.social?.twitterSite
          },
          {
            hid: 'twitter:creator',
            property: 'twitter:creator',
            content: this.social?.twitterCreator
          }
        ]
      };
    }
  }
};
</script>
